exports.components = {
  "component---src-home-tsx": () => import("./../../../src/home.tsx" /* webpackChunkName: "component---src-home-tsx" */),
  "component---src-pages-3-d-printing-mdx": () => import("./../../../src/pages/3D-Printing.mdx" /* webpackChunkName: "component---src-pages-3-d-printing-mdx" */),
  "component---src-pages-anti-static-esd-packaging-mdx": () => import("./../../../src/pages/anti-static-esd-packaging.mdx" /* webpackChunkName: "component---src-pages-anti-static-esd-packaging-mdx" */),
  "component---src-pages-assembly-types-and-levels-mdx": () => import("./../../../src/pages/assembly-types-and-levels.mdx" /* webpackChunkName: "component---src-pages-assembly-types-and-levels-mdx" */),
  "component---src-pages-battery-certification-mdx": () => import("./../../../src/pages/Battery-certification.mdx" /* webpackChunkName: "component---src-pages-battery-certification-mdx" */),
  "component---src-pages-bill-of-landing-mdx": () => import("./../../../src/pages/bill-of-landing.mdx" /* webpackChunkName: "component---src-pages-bill-of-landing-mdx" */),
  "component---src-pages-bill-of-materials-bom-mdx": () => import("./../../../src/pages/bill-of-materials-bom.mdx" /* webpackChunkName: "component---src-pages-bill-of-materials-bom-mdx" */),
  "component---src-pages-blow-molding-mdx": () => import("./../../../src/pages/Blow-Molding.mdx" /* webpackChunkName: "component---src-pages-blow-molding-mdx" */),
  "component---src-pages-boxbuild-assembly-mdx": () => import("./../../../src/pages/boxbuild-assembly.mdx" /* webpackChunkName: "component---src-pages-boxbuild-assembly-mdx" */),
  "component---src-pages-cad-aperture-files-mdx": () => import("./../../../src/pages/CAD-Aperture-Files.mdx" /* webpackChunkName: "component---src-pages-cad-aperture-files-mdx" */),
  "component---src-pages-ce-mdx": () => import("./../../../src/pages/ce.mdx" /* webpackChunkName: "component---src-pages-ce-mdx" */),
  "component---src-pages-cnc-mdx": () => import("./../../../src/pages/CNC.mdx" /* webpackChunkName: "component---src-pages-cnc-mdx" */),
  "component---src-pages-components-mdx": () => import("./../../../src/pages/Components.mdx" /* webpackChunkName: "component---src-pages-components-mdx" */),
  "component---src-pages-custom-clearance-mdx": () => import("./../../../src/pages/custom-clearance.mdx" /* webpackChunkName: "component---src-pages-custom-clearance-mdx" */),
  "component---src-pages-custom-packaging-mdx": () => import("./../../../src/pages/custom-packaging.mdx" /* webpackChunkName: "component---src-pages-custom-packaging-mdx" */),
  "component---src-pages-dfa-mdx": () => import("./../../../src/pages/DFA.mdx" /* webpackChunkName: "component---src-pages-dfa-mdx" */),
  "component---src-pages-dfx-mdx": () => import("./../../../src/pages/DFX.mdx" /* webpackChunkName: "component---src-pages-dfx-mdx" */),
  "component---src-pages-die-casting-mdx": () => import("./../../../src/pages/Die-Casting.mdx" /* webpackChunkName: "component---src-pages-die-casting-mdx" */),
  "component---src-pages-dropshipping-mdx": () => import("./../../../src/pages/dropshipping.mdx" /* webpackChunkName: "component---src-pages-dropshipping-mdx" */),
  "component---src-pages-electroplating-anodizing-mdx": () => import("./../../../src/pages/Electroplating-Anodizing.mdx" /* webpackChunkName: "component---src-pages-electroplating-anodizing-mdx" */),
  "component---src-pages-embossing-debossing-mdx": () => import("./../../../src/pages/Embossing-Debossing.mdx" /* webpackChunkName: "component---src-pages-embossing-debossing-mdx" */),
  "component---src-pages-emc-and-emi-compliance-mdx": () => import("./../../../src/pages/emc-and-emi-compliance.mdx" /* webpackChunkName: "component---src-pages-emc-and-emi-compliance-mdx" */),
  "component---src-pages-extrusion-molding-mdx": () => import("./../../../src/pages/Extrusion-Molding.mdx" /* webpackChunkName: "component---src-pages-extrusion-molding-mdx" */),
  "component---src-pages-fcc-mdx": () => import("./../../../src/pages/fcc.mdx" /* webpackChunkName: "component---src-pages-fcc-mdx" */),
  "component---src-pages-financial-planning-for-scm-mdx": () => import("./../../../src/pages/financial-planning-for-scm.mdx" /* webpackChunkName: "component---src-pages-financial-planning-for-scm-mdx" */),
  "component---src-pages-firmware-configuration-and-software-installation-mdx": () => import("./../../../src/pages/firmware-configuration-and-software-installation.mdx" /* webpackChunkName: "component---src-pages-firmware-configuration-and-software-installation-mdx" */),
  "component---src-pages-functional-testing-for-iot-products-mdx": () => import("./../../../src/pages/functional-testing-for-iot-products.mdx" /* webpackChunkName: "component---src-pages-functional-testing-for-iot-products-mdx" */),
  "component---src-pages-hot-stamping-mdx": () => import("./../../../src/pages/Hot-Stamping.mdx" /* webpackChunkName: "component---src-pages-hot-stamping-mdx" */),
  "component---src-pages-hs-and-hts-codes-mdx": () => import("./../../../src/pages/hs-and-hts-codes.mdx" /* webpackChunkName: "component---src-pages-hs-and-hts-codes-mdx" */),
  "component---src-pages-hydrographics-mdx": () => import("./../../../src/pages/Hydrographics.mdx" /* webpackChunkName: "component---src-pages-hydrographics-mdx" */),
  "component---src-pages-imd-lamination-mdx": () => import("./../../../src/pages/IMD-Lamination.mdx" /* webpackChunkName: "component---src-pages-imd-lamination-mdx" */),
  "component---src-pages-impedance-mdx": () => import("./../../../src/pages/Impedance.mdx" /* webpackChunkName: "component---src-pages-impedance-mdx" */),
  "component---src-pages-in-circuit-testing-ict-for-electrical-testing-mdx": () => import("./../../../src/pages/in-circuit-testing-ict-for-electrical-testing.mdx" /* webpackChunkName: "component---src-pages-in-circuit-testing-ict-for-electrical-testing-mdx" */),
  "component---src-pages-incoming-quality-control-iqc-mdx": () => import("./../../../src/pages/incoming-quality-control-iqc.mdx" /* webpackChunkName: "component---src-pages-incoming-quality-control-iqc-mdx" */),
  "component---src-pages-incoterms-mdx": () => import("./../../../src/pages/Incoterms.mdx" /* webpackChunkName: "component---src-pages-incoterms-mdx" */),
  "component---src-pages-injection-molding-mdx": () => import("./../../../src/pages/Injection-Molding.mdx" /* webpackChunkName: "component---src-pages-injection-molding-mdx" */),
  "component---src-pages-innovation-consulting-mdx": () => import("./../../../src/pages/innovation-consulting.mdx" /* webpackChunkName: "component---src-pages-innovation-consulting-mdx" */),
  "component---src-pages-international-product-returns-mdx": () => import("./../../../src/pages/international-product-returns.mdx" /* webpackChunkName: "component---src-pages-international-product-returns-mdx" */),
  "component---src-pages-invoicing-mdx": () => import("./../../../src/pages/invoicing.mdx" /* webpackChunkName: "component---src-pages-invoicing-mdx" */),
  "component---src-pages-iso-mdx": () => import("./../../../src/pages/ISO.mdx" /* webpackChunkName: "component---src-pages-iso-mdx" */),
  "component---src-pages-labels-mdx": () => import("./../../../src/pages/labels.mdx" /* webpackChunkName: "component---src-pages-labels-mdx" */),
  "component---src-pages-laser-cutting-mdx": () => import("./../../../src/pages/Laser-Cutting.mdx" /* webpackChunkName: "component---src-pages-laser-cutting-mdx" */),
  "component---src-pages-laser-engraving-mdx": () => import("./../../../src/pages/Laser-Engraving.mdx" /* webpackChunkName: "component---src-pages-laser-engraving-mdx" */),
  "component---src-pages-lifecycle-management-mdx": () => import("./../../../src/pages/lifecycle-management.mdx" /* webpackChunkName: "component---src-pages-lifecycle-management-mdx" */),
  "component---src-pages-new-product-introduction-npi-mdx": () => import("./../../../src/pages/new-product-introduction-npi.mdx" /* webpackChunkName: "component---src-pages-new-product-introduction-npi-mdx" */),
  "component---src-pages-new-technology-introduction-nti-mdx": () => import("./../../../src/pages/new-technology-introduction-nti.mdx" /* webpackChunkName: "component---src-pages-new-technology-introduction-nti-mdx" */),
  "component---src-pages-non-conformance-control-and-management-mdx": () => import("./../../../src/pages/non-conformance-control-and-management.mdx" /* webpackChunkName: "component---src-pages-non-conformance-control-and-management-mdx" */),
  "component---src-pages-nsf-mdx": () => import("./../../../src/pages/nsf.mdx" /* webpackChunkName: "component---src-pages-nsf-mdx" */),
  "component---src-pages-package-types-soldering-mdx": () => import("./../../../src/pages/Package-Types-Soldering.mdx" /* webpackChunkName: "component---src-pages-package-types-soldering-mdx" */),
  "component---src-pages-packaging-design-mdx": () => import("./../../../src/pages/packaging-design.mdx" /* webpackChunkName: "component---src-pages-packaging-design-mdx" */),
  "component---src-pages-patenting-mdx": () => import("./../../../src/pages/Patenting.mdx" /* webpackChunkName: "component---src-pages-patenting-mdx" */),
  "component---src-pages-pcb-design-tips-mdx": () => import("./../../../src/pages/PCB-Design-Tips.mdx" /* webpackChunkName: "component---src-pages-pcb-design-tips-mdx" */),
  "component---src-pages-pcb-layers-mdx": () => import("./../../../src/pages/PCB-Layers.mdx" /* webpackChunkName: "component---src-pages-pcb-layers-mdx" */),
  "component---src-pages-pcb-software-file-type-mdx": () => import("./../../../src/pages/PCB-Software-File-Type.mdx" /* webpackChunkName: "component---src-pages-pcb-software-file-type-mdx" */),
  "component---src-pages-pcb-stencil-mdx": () => import("./../../../src/pages/pcb-stencil.mdx" /* webpackChunkName: "component---src-pages-pcb-stencil-mdx" */),
  "component---src-pages-pcb-surface-finish-mdx": () => import("./../../../src/pages/PCB-Surface-Finish.mdx" /* webpackChunkName: "component---src-pages-pcb-surface-finish-mdx" */),
  "component---src-pages-pcb-types-mdx": () => import("./../../../src/pages/PCB-Types.mdx" /* webpackChunkName: "component---src-pages-pcb-types-mdx" */),
  "component---src-pages-polishing-mdx": () => import("./../../../src/pages/Polishing.mdx" /* webpackChunkName: "component---src-pages-polishing-mdx" */),
  "component---src-pages-powder-coating-mdx": () => import("./../../../src/pages/Powder-Coating.mdx" /* webpackChunkName: "component---src-pages-powder-coating-mdx" */),
  "component---src-pages-powder-metallurgy-mdx": () => import("./../../../src/pages/Powder-Metallurgy.mdx" /* webpackChunkName: "component---src-pages-powder-metallurgy-mdx" */),
  "component---src-pages-product-lifecycle-mdx": () => import("./../../../src/pages/product-lifecycle.mdx" /* webpackChunkName: "component---src-pages-product-lifecycle-mdx" */),
  "component---src-pages-pvd-mdx": () => import("./../../../src/pages/PVD.mdx" /* webpackChunkName: "component---src-pages-pvd-mdx" */),
  "component---src-pages-qc-inspection-mdx": () => import("./../../../src/pages/QC-Inspection.mdx" /* webpackChunkName: "component---src-pages-qc-inspection-mdx" */),
  "component---src-pages-qms-mdx": () => import("./../../../src/pages/QMS.mdx" /* webpackChunkName: "component---src-pages-qms-mdx" */),
  "component---src-pages-quality-assurance-qa-mdx": () => import("./../../../src/pages/quality-assurance-qa.mdx" /* webpackChunkName: "component---src-pages-quality-assurance-qa-mdx" */),
  "component---src-pages-sandblasting-mdx": () => import("./../../../src/pages/Sandblasting.mdx" /* webpackChunkName: "component---src-pages-sandblasting-mdx" */),
  "component---src-pages-scm-mdx": () => import("./../../../src/pages/SCM.mdx" /* webpackChunkName: "component---src-pages-scm-mdx" */),
  "component---src-pages-silkscreen-printing-mdx": () => import("./../../../src/pages/Silkscreen-Printing.mdx" /* webpackChunkName: "component---src-pages-silkscreen-printing-mdx" */),
  "component---src-pages-sourcing-channel-mdx": () => import("./../../../src/pages/Sourcing-channel.mdx" /* webpackChunkName: "component---src-pages-sourcing-channel-mdx" */),
  "component---src-pages-sourcing-strategy-mdx": () => import("./../../../src/pages/sourcing-strategy.mdx" /* webpackChunkName: "component---src-pages-sourcing-strategy-mdx" */),
  "component---src-pages-spray-painting-mdx": () => import("./../../../src/pages/Spray-Painting.mdx" /* webpackChunkName: "component---src-pages-spray-painting-mdx" */),
  "component---src-pages-stackup-design-mdx": () => import("./../../../src/pages/Stackup-Design.mdx" /* webpackChunkName: "component---src-pages-stackup-design-mdx" */),
  "component---src-pages-stamping-mdx": () => import("./../../../src/pages/Stamping.mdx" /* webpackChunkName: "component---src-pages-stamping-mdx" */),
  "component---src-pages-structural-foam-molding-mdx": () => import("./../../../src/pages/Structural-Foam-Molding.mdx" /* webpackChunkName: "component---src-pages-structural-foam-molding-mdx" */),
  "component---src-pages-structural-reinforcement-mdx": () => import("./../../../src/pages/Structural-reinforcement.mdx" /* webpackChunkName: "component---src-pages-structural-reinforcement-mdx" */),
  "component---src-pages-supplier-audit-mdx": () => import("./../../../src/pages/Supplier-audit.mdx" /* webpackChunkName: "component---src-pages-supplier-audit-mdx" */),
  "component---src-pages-surface-texturing-mdx": () => import("./../../../src/pages/Surface-Texturing.mdx" /* webpackChunkName: "component---src-pages-surface-texturing-mdx" */),
  "component---src-pages-tolerances-clearance-mdx": () => import("./../../../src/pages/Tolerances-Clearance.mdx" /* webpackChunkName: "component---src-pages-tolerances-clearance-mdx" */),
  "component---src-pages-transport-modes-mdx": () => import("./../../../src/pages/Transport-Modes.mdx" /* webpackChunkName: "component---src-pages-transport-modes-mdx" */),
  "component---src-pages-transportation-costs-mdx": () => import("./../../../src/pages/transportation-costs.mdx" /* webpackChunkName: "component---src-pages-transportation-costs-mdx" */),
  "component---src-pages-vias-holes-mdx": () => import("./../../../src/pages/Vias-Holes.mdx" /* webpackChunkName: "component---src-pages-vias-holes-mdx" */),
  "component---src-pages-warehouse-parts-for-assembly-mdx": () => import("./../../../src/pages/warehouse-parts-for-assembly.mdx" /* webpackChunkName: "component---src-pages-warehouse-parts-for-assembly-mdx" */),
  "component---src-pages-warehousing-mdx": () => import("./../../../src/pages/warehousing.mdx" /* webpackChunkName: "component---src-pages-warehousing-mdx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */)
}

